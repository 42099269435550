import React from 'react'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { resetPassword } from '../../services/authentication'
import spinner from '../../images/Ellipse.png'

const ForgottenPasswordSchema = Yup.object().shape({
  username: Yup.string().email().required(),
})

const initialValues = { username: '', recaptcha: '' }

const ForgottenPasswordForm = ({ handleSubmitState }) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ForgottenPasswordSchema}
        onSubmit={async (values, actions) => {
          try {
            await resetPassword(values.username)
            handleSubmitState()
            actions.resetForm()
          } catch (e) {
            console.log(e)
            handleSubmitState()
          }
        }}
      >
        {({ errors, touched, values, isSubmitting }) => (
          <Form id={'signin'} noValidate={true}>
            <div className={'flex flex-col gap-y-4 mb-3 md:mb-2 w-[344px]'}>
              <div className={'flex flex-col'}>
                <label
                  htmlFor={'username'}
                  className={`text-base font-medium pb-2.5 ${
                    touched.username && errors.username
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  E-postadresse*
                </label>
                <Field
                  className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700
                  ${
                    touched.username && errors.username
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark'
                      : 'bg-white'
                  }`}
                  name={'username'}
                  type={'email'}
                  placeholder={'navn@eksempel.no'}
                />
              </div>
            </div>
            <div className={'flex justify-center'}>
              <button
                className={
                  ' w-full text-center px-[18px] min-w-[158px] max-w-max font-medium px-[18px] pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-blue-main text-blue-lightest hover:bg-blue-hover active:bg-blue-dark'
                }
                type={'submit'}
              >
                {isSubmitting ? (
                  <img
                    src={spinner}
                    alt={'spinner'}
                    className={'animate-spin mx-auto'}
                  />
                ) : (
                  <p>Tilbakestill passord</p>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ForgottenPasswordForm
