import React from 'react'
import { CheckIcon } from '../../images/check-icon'

const Passwordconfirmation = ({ togglePage, handleSubmitState }) => {
  return (
    <div className={'bg-gray-100 h-[calc(100vh-56px)] flex items-center'}>
      <div
        className={
          'xl-container max-w-content mx-auto flex flex-col items-center justify-center gap-y-4 md:gap-y-6'
        }
      >
        <div className={'flex flex-col items-center gap-y-2'}>
          <span
            className={
              'rounded-full bg-blue-lighter h-10 w-10 flex items-center justify-center'
            }
          >
            <CheckIcon color={'blue'} />
          </span>
          <h1 className={'font-semibold lg:font-medium text-4xl lg:text-5xl'}>
            Epost sendt
          </h1>
        </div>
        <div
          className={
            'text-center text-blue-dark text-base md:text-lg max-w-[600px]'
          }
        >
          <p>
            Dersom vi finner e-posten din i systemet vårt vil du få en e-post
            med lenke for tilbakestilling av passordet ditt. Husk å sjekke
            søppelposten din.
          </p>
        </div>

        <button
          className={
            'font-medium px-[18px] pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-blue-main text-blue-lightest hover:bg-blue-hover active:bg-blue-dark'
          }
          onClick={(e) => {
            togglePage(e)
            handleSubmitState()
          }}
        >
          Tilbake til innlogging
        </button>
      </div>
    </div>
  )
}

export default Passwordconfirmation
