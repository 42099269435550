import React from 'react'
import PropTypes from 'prop-types'
import SignInForm from '../forms/signin'

const LoginLayout = ({ title, togglePage, location }) => {
  return (
    <div className={'bg-gray-100 h-screen'}>
      <div
        className={
          'xl-container md:px-0 max-w-[376px] mx-auto flex flex-col items-center pt-20 md:pt-32 lg:pt-20 gap-y-10 md:gap-y-24 lg:gap-y-20'
        }
      >
        <h1
          className={
            'font-semibold lg:font-medium text-3xl md:text-4xl lg:text-5xl'
          }
        >
          {title}
        </h1>
        <div className={'w-full'}>
          <SignInForm togglePage={togglePage} location={location} />
        </div>
      </div>
    </div>
  )
}

export default LoginLayout

LoginLayout.propTypes = {
  title: PropTypes.string,
}
