import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import { graphql, navigate } from 'gatsby'
import LoginLayout from '../components/login/login-layout'
import SEO from '../components/seo'
import ForgottenPasswordLayout from '../components/forgotten-password/forgotten-password-layout'
import Passwordconfirmation from '../components/password-confirmation/passwordconfirmation'
import MinimalFooter from '../components/footer/minimal-footer'
import { isLoggedIn } from '../services/authentication'

const LoginTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const [isSubmitted, setSubmitted] = useState(false)
  const [showPasswordReset, setPasswordReset] = useState(false)

  const handleSubmitState = () => {
    setSubmitted(!isSubmitted)
  }

  const togglePage = (e) => {
    e.preventDefault()
    setPasswordReset(!showPasswordReset)
  }
  useEffect(() => {
    isLoggedIn() && navigate('/')
  }, [])

  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      {!showPasswordReset && (
        <LoginLayout
          title={page.title}
          togglePage={togglePage}
          location={location}
        />
      )}
      {showPasswordReset &&
        (isSubmitted ? (
          <Passwordconfirmation
            togglePage={togglePage}
            handleSubmitState={handleSubmitState}
          />
        ) : (
          <ForgottenPasswordLayout
            title={page.title}
            togglePage={togglePage}
            handleSubmitState={handleSubmitState}
          />
        ))}
      <MinimalFooter />
    </Layout>
  )
}

export default LoginTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpLoginTemplate {
          templateName
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
  }
`