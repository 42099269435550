import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'

import { EyeOpen } from '../../images/eye-open'
import { Button } from '../common/button'
import { login } from '../../services/authentication'
import { EyeClosed } from '../../images/eye-closed'
import {
  enteredFieldValue,
  passwordToggleEvent,
} from '../google-tag-manager/events'

import spinner from '../../images/Ellipse.png'

const SignInSchema = Yup.object().shape({
  username: Yup.string().email().required(),
  password: Yup.string().required(),
})

const initialValues = { username: '', password: '' }

const SignInForm = ({ togglePage, location }) => {
  const [showPassword, setShowPassword] = useState(false)
  const togglePassword = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword)
    passwordToggleEvent()
  }

  const [referrer, setReferrer] = useState(null)

  useEffect(() => {
    if (
      location.state &&
      (location?.state?.referrer?.includes('kasus') ||
        location?.state?.referrer?.includes('statistikk') ||
        location?.state?.referrer?.includes('webinarer'))
    ) {
      setReferrer(location.host + location.state.referrer)
    } else {
      setReferrer(process.env.GATSBY_REFERRER_URL)
    }
  }, [])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={async (values, { setErrors }) => {
          try {
            await login(values, referrer)
          } catch (err) {
            if (err.response.status !== 200) {
              setErrors('error')
            }
            // throw err
          }
        }}
      >
        {({ errors, touched, values, isValid, submitCount, isSubmitting }) => (
          <Form id={'signin'} noValidate={true}>
            <div className={'flex flex-col gap-y-4 mb-4'}>
              <div className={'flex flex-col'}>
                <label
                  htmlFor={'username'}
                  className={`text-base font-medium pb-2.5 ${
                    touched.username && errors.username
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  E-postadresse*
                </label>
                <Field
                  autoComplete={'username email'}
                  id={'email'}
                  className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700
                  ${
                    touched.username && errors.username
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                  name={'username'}
                  type={'email'}
                  placeholder={'navn@eksempel.no'}
                  onBlur={() => {
                    if (values.username.length > 0) {
                      enteredFieldValue('e-mail')
                    }
                  }}
                />
              </div>
              <div className={'flex flex-col'}>
                <label
                  htmlFor={'password'}
                  className={`text-base font-medium pb-2.5  ${
                    touched.password && errors.password
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  Passord*
                </label>

                <div className="relative w-full">
                  <Field
                    id={'current-password'}
                    autoComplete={'current-password'}
                    className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700
                  ${
                    touched.password && errors.password
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }
               `}
                    name={'password'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={'Passordet ditt'}
                    onBlur={() => {
                      if (values.password.length > 0) {
                        enteredFieldValue('password')
                      }
                    }}
                  ></Field>
                  <button
                    onClick={(e) => togglePassword(e)}
                    type={'button'}
                    className="absolute inset-y-0 right-0 flex items-center px-2"
                    tabIndex={-1}
                  >
                    {!showPassword ? <EyeOpen /> : <EyeClosed />}
                  </button>
                </div>
              </div>
              {!isValid && submitCount > 0 && (
                <div className={'rounded-lg bg-error/40 px-5 py-4'}>
                  <p className={'text-errortext font-medium text-sm'}>
                    Epost eller passord er feil. Vennligst prøv igjen.
                  </p>
                </div>
              )}
            </div>
            <div className={'flex justify-center gap-x-2'}>
              <Button
                variant={'outlined'}
                color={'gray'}
                fullwidth={'true'}
                isExternal={false}
                path={'/registrer-bruker'}
              >
                Registrer bruker
              </Button>
              <button
                className={`w-full text-center font-medium pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-blue-main text-blue-lightest hover:bg-blue-hover active:bg-blue-dark
                  `}
                type={'submit'}
              >
                {isSubmitting ? (
                  <img
                    src={spinner}
                    alt={'spinner'}
                    className={'animate-spin mx-auto'}
                  />
                ) : (
                  <p>Logg inn</p>
                )}
              </button>
            </div>
            <div className={'flex justify-center gap-x-4 max-h-[47px] mt-8'}>
              <button
                className={
                  'text-center rounded-full text-[17px] leading-[17px]  focus:ring focus:ring-orange-light font-medium bg-transparent text-blue-dark rounded-none py-2 px-0 w-max border-r-0 border-l-0 border-t-0 border-b-2 border-orange-main hover:border-orange-lighter focus:ring-0 active:border-orange-lighter'
                }
                onClick={(e) => togglePage(e)}
              >
                Glemt passord?
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SignInForm