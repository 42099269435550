import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ForgottenPasswordForm from '../forms/forgotten-password'
import { resetPasswordPageViewEvent } from '../google-tag-manager/events'

const ForgottenPasswordLayout = ({ togglePage, handleSubmitState }) => {
  useEffect(() => {
    resetPasswordPageViewEvent()
  }, [])
  return (
    <div className={'bg-gray-100 min-h-screen'}>
      <div className={'xl-container'}>
        <button
          className={
            ' my-4 text-center px-[18px] pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] focus:ring focus:ring-orange-light font-medium bg-transparent text-blue-dark border-2 border-blue-lighter hover:text-blue-main disabled:border-gray-400 disabled:text-gray-700'
          }
          onClick={(e) => togglePage(e)}
        >
          Avbryt
        </button>
      </div>
      <div
        className={
          'xl-container md:px-0 max-w-content mx-auto flex flex-col items-center  pt-12 lg:pt-24 gap-y-4 md:gap-y-6'
        }
      >
        <h1
          className={
            'font-semibold md:font-medium text-3xl md:text-4xl lg:text-5xl text-blue-dark'
          }
        >
          Glemt passordet ditt?
        </h1>
        <div className={'text-center text-blue-dark text-base md:text-lg'}>
          <p>Det kan skje den beste.</p>
          <p>
            Fyll inn e-posten din så sender vi deg en lenke for nullstilling av
            passordet ditt.
          </p>
        </div>
        <div className={'max-w-[376px] pt-0 md:pt-2'}>
          <ForgottenPasswordForm handleSubmitState={handleSubmitState} />
        </div>
      </div>
    </div>
  )
}

export default ForgottenPasswordLayout

ForgottenPasswordLayout.propTypes = {
  title: PropTypes.string,
}
